<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn text class="mr-2" v-on="on"> <v-icon left>mdi-wrench-outline</v-icon>ADMIN </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in items" :key="index" :to="item.url">
        <v-list-item-title
          ><v-icon left>{{ item.icon }}</v-icon
          >{{ item.title }}</v-list-item-title
        >
      </v-list-item>
      <v-list-item :to="path">
        <v-list-item-title>Test</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BarSettingsMenu',
  data: () => ({
    items: [
      { title: 'Мини Игры', icon: 'mdi-pirate', url: '/miniGames' },
      {
        title: 'Календарь',
        icon: 'mdi-calendar-heart',
        url: '/calendars'
      }
    ]
  }),
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    path() {
      if (this.user.payments && this.user.payments.Test) {
        return '/studios/home/Test'
      } else {
        return '/studios/preview/Test'
      }
    }
  }
}
</script>

<style scoped />
